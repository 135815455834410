<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header>
      <div class="pageName">
        <span><el-link href="/" icon="el-icon-s-home" :underline="false">翔安安全生产云待办考评系统</el-link></span>
      </div>
      <el-link href="/" icon="el-icon-document" class="linkscore" v-if="!isManage" :underline="false">我的待办</el-link>
      <el-link href="/#/companions" icon="el-icon-user-solid" class="linkscore" v-if="!isManage" :underline="false">同办人员</el-link>
      <div v-if="isManage">
        <!-- <el-link href="/" icon="el-icon-success" class="linkscore">任务审核</el-link> -->
        <el-dropdown>
          <span class="el-dropdown-link">
            任务管理<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-document"><el-link href="/#/welcome" :underline="false">任务列表</el-link></el-dropdown-item>
            <el-dropdown-item icon="el-icon-circle-plus-outline"><el-link href="/#/taskAdd" :underline="false">发布任务</el-link></el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-link href="/#/department" icon="el-icon-office-building" class="linkscore" :underline="false">成员单位</el-link>
        <el-link href="/#/users" icon="el-icon-user-solid" class="linkscore" :underline="false">用户管理</el-link>
      </div>
      <el-link href="/#/score" icon="el-icon-s-data" class="linkscore" :underline="false">评分统计</el-link>
      <div>
        <el-button type="success" @click="logout" size="mini">安全退出</el-button>
      </div>
    </el-header>
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '0px' : '160px'">
        <div class="toggle-button" @click="toggleCollpase">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409EFF"
          :unique-opened="true"
          :collapse="isCollapse"
          :collapse-transition="false"
          :router="true"
          :default-active="activePath"
        >
          <!-- 一级菜单 -->
          <el-submenu
            :index="item.id + ''"
            v-for="item in menuList"
            :key="item.id"
          >
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[item.id]"></i>
              <!-- 文本 -->
              <span>{{ item.authName }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item
              :index="'/' + sunItem.path"
              v-for="sunItem in item.children"
              :key="sunItem.id"
              @click="saveActivePath('/' + sunItem.path)"
            >
              <template slot="title">
                <!-- 二级菜单的模板区域 -->
                <i class="el-icon-menu"></i>
                <!-- 图标 -->
                <span>{{ sunItem.authName }}</span>
                <!-- 文本 -->
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主题 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      // 左侧菜单数据
      menuList: [],
      iconsObj: {
        // 一级菜单的icon图标
        147: 'el-icon-magic-stick',
        125: 'iconfont icon-users',
        103: 'iconfont icon-tijikongjian',
        101: 'iconfont icon-shangpin',
        102: 'iconfont icon-danju',
        145: 'iconfont icon-baobiao'
      },
      isCollapse: true, // 是否折叠属性
      activePath: '/welcome',
      isManage: false,
      welcome: {
        authName: '欢迎光临',
        id: 147,
        order: 1,
        path: '/welcome',
        children: [
          {
            authName: 'Welcome',
            id: 124,
            order: 1,
            path: 'welcome',
            children: []
          }
        ]
      }
    }
  },
  created() {
    // this.getMenuList()
    // console.log(this.activePath)
    const path = window.sessionStorage.getItem('activePath')
    if (path) {
      this.activePath = path
    } else {
      this.activePath = '/welcome'
    }
    const userType = window.sessionStorage.getItem('userType')
    // console.log(userType)
    if (userType === '1') {
      this.isManage = true
    } else {
      this.isManage = false
    }
  },
  methods: {
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 获取所有的菜单
    async getMenuList() {
      const { data: res } = await this.$http.get('/menus')
      if (res.meta.status !== 200) return this.$message.error(res.meta.msg)
      this.$message.success(res.meta.msg)
      res.data.unshift(this.welcome)
      this.menuList = res.data
      // console.log(res, 'menus')
    },
    // 点击按钮,切换菜单的折叠和展开
    toggleCollpase() {
      this.isCollapse = !this.isCollapse
    },
    saveActivePath(activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
      // console.log(this.$route.path)
    }
  }
}
</script>

<style lang="less" scoped>
.hwelcome {
  color: #fff;
  font-size: 15px;
  height: 25px;
  text-align: center;
  background: green;
  span {
    margin-left: 10px;
  }
}
.home-container {
  height: 100%;
}
.el-header {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  background: linear-gradient(90deg,#1d42ab,#2173dc,#1e93ff);
  align-items: center;
  color: #fff;
  font-size: 20px;
  > div {
    display: flex;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    span {
      margin-left: 15px;
      a {
        font-size: 16px;
        color:#fff;
      }
      a:hover {
        color:#fff;
        text-decoration:none;
      }
    }
  }
}
.el-header .pageName{
  display: flex;
  flex: auto;
}
.el-aside {
  background-color: #333744;
  .el-menu {
    border-right: none;
  }
}
.el-main {
  background-color: #eaedf1;
}
.iconfont {
  padding-right: 10px;
}
.linkscore{
  margin-right: 20px;
  color:#eaedf1;
}
.linkscore:hover{color:#fff}
.toggle-button {
  background: #4a5064;
  font-size: 10px;
  text-align: center;
  line-height: 24px;
  color: #fff;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.el-dropdown-link {
    cursor: pointer;
    color: #fff;
    margin-right: 20px;
    i {
      margin-right: 7px;
    }
  }
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
