<template>
  <div class="welcome" v-cloak>
    <el-page-header @back="goBack" content="详情页面"></el-page-header>
    <el-row :gutter="20">
      <el-col :span="14">
         <h3 v-cloak>{{taskDetail.name}}</h3>
      </el-col>
      <el-col :span="4">
        <div class="statics_item">
          <div v-cloak>{{taskDetail.end_date_time}}</div>
          截止时间
        </div>
      </el-col>
      <el-col :span="2">
        <div class="statics_item">
          <div v-cloak>{{taskDetail.days | dateToEnd}}</div>
          剩余时间
        </div>
      </el-col>
      <el-col :span="2">
        <div class="statics_item">
          <div v-cloak>{{taskDetail.completed}}</div>
          已提交
        </div>
      </el-col>
      <el-col :span="2">
        <div class="statics_item">
          <div v-cloak>{{taskDetail.quantity-taskDetail.completed}}</div>
          未完成
        </div>
      </el-col>
    </el-row>
    <el-card>
      <!-- 任务详情 -->
      <el-divider content-position="left">任务说明</el-divider>
      <div v-html="taskDetail.description" class="textArea"></div>
      <div class="textArea pb-40">发布时间：{{taskDetail.created_at}}</div>
      <!-- 部门完成情况显示 -->
        <el-tabs v-model="activeName">
        <el-tab-pane name="first">
          <span slot='label'>审核中 {{doingList.length}}</span>
          <div class="checkList">
           <ul>
            <li v-for="(dpitem, dpindex) in doingList" :key="dpitem.id">
              <div class="number">{{dpindex + 1}}</div>
              <div class="verify" v-if="dpitem.verify_info !== null">
                <!-- 审核通过的显示 -->
                <el-tag size="mini" type="success" v-if="dpitem.verify_info.status == 1">审核通过</el-tag>
                <el-tag size="mini" type="danger" v-else-if="dpitem.verify_info.status == 2">审核未通过</el-tag>
                <div>{{ dpitem.verify_info.remark }}</div>
              </div>
              <div class="content">
                <div><span class="departmentName">{{dpitem.department_name}}</span></div>
                    <div class="filesList" v-if="dpitem.attachments != null">
                      <div v-for="(item,index) in JSON.parse(dpitem.attachments).files" :key="index">
                        <div v-if="item.ext === 'docx' ||  item.ext === 'doc'">
                          <i class="el-icon-document"></i>
                          {{ item.file_name }} <el-link :href="item.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
                        </div>
                        <div v-else-if="item.ext === 'pdf'">
                          <i class="el-icon-collection-tag"></i>
                          {{ item.file_name }} <el-link :href="item.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
                        </div>
                        <div v-else-if="item.ext === 'jpg' || item.ext === 'gif' || item.ext === 'png'">
                          <el-image :src="item.url" style="width: 40px; height: 40px" :preview-src-list="[item.url]" lazy>
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </div>
                        <div shadow="hover" v-else-if="item.ext === 'rar' || item.ext === 'zip'">
                          <i class="el-icon-collection"></i>
                            {{ item.file_name }} <el-link :href="item.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
                        </div>
                        <div shadow="hover" v-else>
                          <i class="el-icon-collection"></i>
                            {{ item.file_name }} <el-link :href="item.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
                        </div>
                      </div>
                    </div>
                <div v-if="dpitem.updated_at != null" class="pt-10"><i class="el-icon-time"></i>提交时间：{{dpitem.updated_at}}
                     <div class="controlArea">
                       <!-- 未审核通过的显示 -->
                       <el-button-group>
                        <el-button type="success" icon="el-icon-folder-checked" size="mini" @click="jobDone(dpitem.id)">审核通过</el-button>
                        <el-button type="primary" icon="el-icon-folder-delete" size="mini" @click="jobDoneFail(dpitem.id)">不合格</el-button>
                      </el-button-group>
                    </div>
                  </div>
              </div>
            </li>
          </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane name="third">
          <span slot='label'>已完成 {{doneList.length}}</span>
          <div class="checkList">
           <ul>
            <li v-for="(ditem, dindex) in doneList" :key="dindex">
              <div class="number">{{dindex + 1}}</div>
              <div class="content">
                <div><span class="departmentName">{{ditem.department_name}}</span></div>
                <div v-if="ditem.attachments != null">
                    <div class="filesList" v-if="JSON.parse(ditem.attachments).files!= null">
                      <div v-for="(item,index) in sortFilesByEx(JSON.parse(ditem.attachments).files)" :key="index">
                        <div v-if="item.ext === 'docx' ||  item.ext === 'doc'" class="cls">
                          <div v-for="file in item.objects" :key="file.id">
                          <i class="el-icon-document"></i>
                          {{ file.file_name }}
                          <el-link :href="file.url" type="primary"
                          class="downloadText"
                          target="_blank">下载<i class="el-icon-download el-icon--right"></i>
                          </el-link>
                        </div>
                        </div>
                        <div v-else-if="item.ext === 'pdf'" class="cls">
                          <div v-for="file in item.objects" :key="file.id">
                          <i class="el-icon-collection-tag"></i>
                          {{ file.file_name }} <el-link :href="file.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
                        </div>
                        </div>
                        <div v-else-if="item.ext === 'jpg' || item.ext === 'gif' || item.ext === 'png'" class="imgItem">
                          <div v-for="file in item.objects" :key="file.id">
                          <el-image :src="file.url" style="width: 40px; height: 40px" :preview-src-list="filterImagesList(item.objects)">
                            <div slot="placeholder" class="image-slot">
                              加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                          </div>
                        </div>
                        <div class="cls" v-else>
                          <div v-for="file in item.objects" :key="file.id">
                          <i class="el-icon-collection"></i>
                          {{ file.file_name }} <el-link :href="file.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
                          </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ditem.completed_at != null" class="pt-10 cls"><i class="el-icon-time"></i> 完成时间：{{ditem.completed_at}}</div>
              </div>
            </li>
          </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane name="second">
          <span slot='label'> 未完成 {{taskDetail.quantity-taskDetail.completed}}</span>
          <div class="taskDepartment">
          <ul>
            <li v-for="(item, index) in unDoList" :key="index">
              <span class="number">{{index + 1}}</span>
              <span class="status">
                <el-tooltip :content="item.status_name" placement="right">
                <i class="el-icon-right default"></i>
                </el-tooltip>
              </span>
              <span class="title">{{item.department_name}}</span>
              <span v-if="item.completed_at != null">{{item.completed_at}}</span>
            </li>
          </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
export default {
  name: 'Taskdetail',
  data() {
    return {
      activeName: 'first',
      // 页面名称
      pagetitle: '任务详情',
      // 部门完成情况请求参数
      departmentQs: {
        task_id: 1,
        complete_status: null,
        pageSize: 100
      },
      taskDetail: {},
      // 部门完成情况列表
      departmentList: [],
      taskDetailQsObj: {
        id: 0
      },
      taskDoQuery: {},
      isNull: {
        attachments: {
          files: [
            {
              id: null,
              ext: null,
              file_name: null,
              url: null
            }
          ],
          images: [
            {
              id: null,
              ext: null,
              file_name: null,
              url: null
            }
          ]
        },
        verify_info: {
          status: 0,
          remark: null
        }
      },
      unDoList: [],
      doneList: [],
      doingList: [],
      taskVerifyQs: {
        id: [], // 单位任务id
        status: 1, // 任务状态1:审核通过2:审核不通过
        remark: null // 审核备注
      }
    }
  },
  filters: {
    taskFormat(val) {
      let statusText = ''
      if (val === 1) {
        statusText = '未完成'
      }
      if (val === 2) {
        statusText = '已过期'
      }
      if (val === 3) {
        statusText = '已完成'
      }
      return statusText
    }
  },
  created() {
    if (this.$route.query.id) {
      this.departmentQs.task_id = this.$route.query.id
      this.getTaskDetail(this.$route.query.id)
    }
  },
  methods: {
    // 取出图片数组
    filterImagesList(arrObj) {
      const urlList = (objs, property) => objs.map((item) => item[property])
      const resUrlList = urlList(arrObj, 'url')
      return resUrlList
    },
    // 重新排序一下数组
    sortFilesByEx(objects) {
      const sortedObjects = Object.entries(
        objects.reduce((acc, obj) => {
          const { ext } = obj
          if (!acc[ext]) {
            acc[ext] = []
          }
          acc[ext].push(obj)
          return acc
        }, {}))
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([ext, objects]) => ({ ext, objects }))
        .flat()
      console.log(sortedObjects)
      return sortedObjects
    },
    // 返回页面
    goBack() {
      this.$router.push('welcome')
    },
    // 标签页面点击
    handleClick(tab, event) {
      console.log(tab, event)
    },
    // 操作审核通过
    async jobDone(val) {
      const confirmResult = await this.$confirm('审核通过后不可撤回, 是否继续?', '通过', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('操作已取消!')
      }
      this.taskVerifyQs.id[0] = val
      const { data: res } = await this.$http.post('project/task/verify', this.taskVerifyQs)
      if (res.code !== 200) {
        return this.$message.error('操作失败!')
      }
      this.$message.success('审核操作成功!')
      this.getTaskDetail(val)
      // this.$message.success('操作成功!')
    },
    // 操作审核不通过
    async jobDoneFail(val) {
      const confirmResult = await this.$confirm('操作将发送短信通知单位重新提交, 是否继续?', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('操作已取消!')
      }
      this.taskVerifyQs.id[0] = val
      this.taskVerifyQs.status = 2
      this.taskVerifyQs.remark = '文件未上传或不完整'
      const { data: res } = await this.$http.post('project/task/verify', this.taskVerifyQs)
      if (res.code !== 200) {
        return this.$message.error('操作失败!')
      }
      this.$message.success('操作成功!')
      // this.$message.success('操作成功!')
      this.getTaskDetail(val)
    },
    async getTaskDetail(val) {
      if (val) {
        this.taskDetailQsObj.id = val * 1
        this.taskDoQuery.task_id = val * 1
        this.departmentQs.task_id = val * 1
      }
      const { data: res } = await this.$http.post('project/task/detail', this.taskDetailQsObj)
      if (res.code !== 200) {
        return this.$message.error('详情打开失败!')
      }
      // console.log(res.data)
      this.taskDetail = res.data
      console.log(this.taskDetail)
      this.getDepartmentList()
    },
    getList(status) {
      this.queryInfo.status = status
      this.getTaskList()
    },
    // 单个审核
    async removeById(id) {
      const confirmResult = await this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('删除操作已取消!')
      }
      // const { data: res } = await this.$http.delete('goods/' + id)
      // if (res.meta.status !== 200) {
      //   return this.$message.error('删除失败!')
      // }
      this.$message.success('删除成功!')
      // this.getGoofsList()
    },
    // 查看已经完成部门
    async getDepartmentList() {
      const { data: res } = await this.$http.post('project/task/departments', this.departmentQs)
      if (res.code !== 200) {
        return this.$message.error('数据查询失败!')
      }
      this.departmentList = res.data
      // console.log(res.data)
      this.doingList = this.departmentList.filter(item => { return item.attachments !== null && item.status_name !== '已完成' })
      this.doneList = this.departmentList.filter(item => { return item.status_name === '已完成' })
      this.unDoList = this.departmentList.filter(item => { return item.status_name === '进行中' || item.status_name === '已逾期' })
      if (this.doingList.length === 0) {
        // this.doingList.push(this.isNull)
        this.activeName = 'third'
      } else {
        if (this.doneList.length === 0) {
        // this.doneList.push(this.isNull)
          this.activeName = 'second'
        }
      }
      console.log(this.doneList)
    },
    downloadFile(url, fileName) {
      // const { data: res } = await this.$http.post(url)
      // if (res.code !== 200) {
      //   console.log(res)
      // }
      // console.log(res)
      var xhr = new XMLHttpRequest()
      xhr.open('GET', url)
      xhr.responseType = 'blob'
      xhr.setRequestHeader('token', 'Bearer ' + window.sessionStorage.getItem('token'))
      // console.log('点击了下载' + url + fileName)
      xhr.onload = () => {
        if (xhr.code === 200) {
          // console.log(xhr)
          this.saveAs(fileName, xhr.response)
        }
      }
    },
    // 下载文件
    saveAs(name, data) {
      const urlObject = window.URL
      var exportBlob = new Blob([data])
      var saveLink = document.createElement('a')
      saveLink.href = urlObject.createObjectURL(exportBlob)
      saveLink.download = name
      saveLink.click()
    }
  }
}
</script>

<style lang="less" scoped>
[v-cloak]{
  display: none;
}
.el-page-header{margin-bottom:20px;}
.welcome {
  position: relative;
  font-size: 20px;
  text-align: left;
  color:#000;
  background: #fff;
  padding:15px 28px;
  min-height: 100%;
  h3 {
    margin: 0 auto 20px;
    font-size: 30px;
  }
}
.statics_item{
    text-align:center;
    color:#222;
    font-size: 12px;
    margin-bottom:20px;
    div{
      font-size: 14px;
      color: #5574ff;
    }
  }
.checkList ul{ overflow:hidden;}
.checkList ul li{
  font-size: 12px;
  display: flex;
  align-items: top;
  border-bottom:1px solid #f2f2f2;
  padding:20px 0;
  span{color:#333;}
}
.checkList ul li .content{ flex: auto;}
.checkList ul li .verify{ flex:none; margin-right: 20px; width:80px;}
.checkList ul li .number{width:24px; flex:none; padding-top:3px;}
.title{ color: #000;padding:0 10px;}
.status{ color:#5574ff}
.textArea{ font-size: 14px;}
.pb-40{padding-bottom:40px;}
.pb-20{padding-bottom:20px;}
.imgShow img{max-width: 80px; width:60px;}
.departmentName{ font-size: 16px; font-weight: bold; padding-bottom:10px; display: block;}
.controlArea{padding-top: 10px;}

.filesList{ align-items: left;}
.filesList div{ text-align:left;}
.imgItem{ padding-top: 8px;float:left; zoom:1; margin-bottom:8px}
.imgItem div{ width: 50px; float:left;}
.cls{clear: both;}

.taskDepartment ul{ overflow:hidden; padding-top: 20px; max-width:750px;}
.taskDepartment ul li{
  font-size: 14px;
  display: flex;
  padding:2px 0;
  float: left;
  width:50%;
  align-items: center;
  span{color:#333}
}
.downloadText{ font-size: 12px; color:#5574ff}
.el-card{width:100%}
.taskDepartment ul li .title{ flex:none; width:120px;}
.taskDepartment ul li .success{ color: #67C23A;}
.taskDepartment ul li .default{ color:#5574ff}
.taskDepartment ul li .danger{ color:peru}
.taskDepartment ul li .number{width:24px; flex:none; color:#888;}
.taskDepartment ul li .date{color:#888; font-size: 12px; line-height: 14px;}
.pt-10{ padding-top: 10px;}

</style>
