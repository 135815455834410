<template>
  <div class="welcome">
    <h3>{{pagetitle}}<span class="total">{{ctotal}}</span></h3>
     <div class="score" v-show="showScore">
      {{score}}
      <p>年度得分</p>
      <span>{{statusYear}}</span>
    </div>
    <el-card>
      <!-- 按钮搜索/添加区域 -->
      <el-row :gutter="20">
        <!-- <el-col :span="7">
          <el-input placeholder="请输入内容" @clear="getGoofsList" clearable v-model="queryInfo.query">
            <el-button slot="append" icon="el-icon-search" @click="getGoofsList"></el-button>
          </el-input>
        </el-col> -->
        <el-col :span="24">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-button-group>
              <el-button  @click="getList()">全部 {{ ctotal}}</el-button>
              <el-button type="primary" @click="getList(1)">未完成 {{ this.filteredItems_progress.length}}</el-button>
              <el-button type="success" @click="getList(3)">已完成 {{ this.filteredItems_complete.length}}</el-button>
              <el-button type="danger" @click="getList(2)">已过期 {{ this.filteredItems_expired.length}}</el-button>
              </el-button-group>
            </el-col>
            <el-col :span="6" :offset="6">
              <!-- <el-input
                placeholder="请输入内容"
                prefix-icon="el-icon-search"
                v-model="input2">
              </el-input> -->
            </el-col>
           </el-row>
        </el-col>
      </el-row>
      <!-- table区域 -->
      <el-row>
        <el-table :data="taskList" stripe style="width: 100%" v-loading="loading" ref="filterTable">
          <el-table-column type="index" label="#" :index="indexMethod" width="50px"></el-table-column>
          <el-table-column prop="id" label="Id" width="60px"></el-table-column>
          <el-table-column prop="name" label="任务标题">
             <template v-slot="scope">
                <el-button type="text" size="mini" icon="el-icon-delete" :disabled="scope.row.completed !== 0" @click="removeById(scope.row.id)" v-if="!showScore"></el-button>
                <el-link @click="getTaskDetail(scope.row.id)">{{scope.row.name}}</el-link>
                <el-tag v-if="scope.row.level === 2 && scope.row.status === 1"
              type="danger"
              size="mini"
              effect="dark">
                急
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="任务状态" width="100px">
            <template v-slot="scope">
              <el-tag size="mini" type="danger" v-if="scope.row.status === 2">{{ scope.row.status | taskFormat}}</el-tag>
              <el-tag size="mini" v-else-if="scope.row.status === 1">{{ scope.row.status | taskFormat}}</el-tag>
              <el-tag size="mini" type="success" v-else-if="scope.row.status === 3">{{ scope.row.status | taskFormat}}</el-tag>
              <el-tag size="mini" type="info" v-else>{{ scope.row.status | taskFormat}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="总进度" width="200" v-if="!showScore">
            <template v-slot="scope">
              <el-progress :percentage="Math.floor(scope.row.completed / scope.row.quantity * 100)"></el-progress>
            </template>
          </el-table-column>
          <el-table-column prop="end_date_time" column-key="end_date_time" :filters='tableDiffFilter' label="到期时间" width="120px" :filter-method="filterDate" sortable>
            <template v-slot="scope">
              {{dateMoment(scope.row.end_date_time)}}
            </template>
          </el-table-column>
          <el-table-column prop="end_date_time" width="220px" label="创建时间" sortable>
            <template v-slot="scope">
              <el-tooltip :content="'截止日期：' + scope.row.end_date_time" placement="right" effect="light">
                <el-button type="text" size="mini" icon="el-icon-alarm-clock"></el-button>
              </el-tooltip>
              {{ scope.row.created_at }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180px">
            <template v-slot="scope">
              <el-button-group>
                <el-button type="primary" size="mini" icon="el-icon-view" @click="getTaskDetail(scope.row.id)"></el-button>
                <el-button type="primary" :disabled="!(scope.row.status === 1)" size="mini" icon="el-icon-edit" @click="editTaskbyId(scope.row.id)" v-if="!showScore"></el-button>
                <el-button type="success" size="mini" icon="el-icon-more" @click="openById(scope.row.id)" v-if="!showScore"></el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 30, 50, 100, 1000]"
        :page-size="queryInfo.pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="ctotal"
      >
      </el-pagination>
    </el-card>
    <!-- 弹出区域 -->
    <el-drawer
      :title="taskDetail.name"
      :visible.sync="drawer"
      :size="720"
      :with-header="true" v-if="taskDetail != ''">
      <div class="drawer__body">
        <!-- 统计 -->
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="statics_item">
              <div>{{taskDetail.end_date_time}}</div>
              截止时间
            </div>
          </el-col>
          <el-col :span="8">
            <div class="statics_item">
              <div>{{taskDetail.days | dateToEnd }}</div>
              剩余时间
            </div>
          </el-col>
          <el-col :span="8">
            <div class="statics_item">
              <div>{{taskDetail.completed}} / {{taskDetail.quantity}}</div>
              已完成/全部
            </div>
          </el-col>
        </el-row>
        <!-- 任务详情 -->
        <el-divider content-position="left">任务说明</el-divider>
        <div v-html="taskDetail.description" class="content"></div>
        <!-- 任务文件 -->
        <div v-if="taskDetail.attachments !== null && taskDetail.attachments !== undefined" class="filesList pb-20">
          <div v-for="item in taskDetail.attachments.files" :key="item.id">
            <div v-if="item.ext === 'docx' ||  item.ext === 'doc'">
              <i class="el-icon-document"></i>
              {{ item.file_name}}{{ item.name }}<el-link :href="item.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
            </div>
            <div v-else-if="item.ext === 'pdf'">
              <i class="el-icon-collection-tag"></i>
              {{ item.file_name }}{{ item.name }}<el-link :href="item.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
            </div>
            <div v-else-if="item.ext === 'rar'">
              <i class="el-icon-collection"></i>
              {{ item.file_name }}{{ item.name }}<el-link :href="item.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
            </div>
            <div v-else>
              <i class="el-icon-notebook-1"></i>
              {{ item.file_name }}{{ item.name }}<el-link :href="item.url" type="primary" class="downloadText" target="_blank">下载<i class="el-icon-download el-icon--right"></i> </el-link>
            </div>
          </div>
        </div>
         <!-- 显示任务状态 未提交，提交审核中，审核不通过，审核通过完成-->
         <div class="jobDone" v-if="taskDetail.current_department_completed"><svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" fill="none" viewBox="0 0 79 79">
            <circle cx="39.5" cy="39.5" r="38" stroke="#5574FF" stroke-opacity=".2" stroke-width="3"/>
            <path fill="#5574FF" fill-opacity=".3" d="m18.246 48.56 4.6 6.583c1.146 1.639 2.081 1.552 4.219.058l4.93-3.446c2.165-1.513 2.09-2.514.656-5.124-.338.159-.89.29-1.294.3 1.315 2.243 1.403 2.845-.04 3.853l-4.996 3.492c-1.443 1.008-1.869 1.013-2.446.187l-4.62-6.61-1.01.706Zm2.054 1.765.687.984 7.738-5.408-.688-.984-7.737 5.408Zm-4.322-3.558.678.97 8.577-5.994 3.547 5.075 1.01-.705-4.225-6.046-9.587 6.7Zm16.962-7.404.623.892 7.134-4.986-.623-.892-7.134 4.986Zm-.556 3.98.65.932 11.633-8.13-.65-.931-11.633 8.13Zm3.898-1.767c1.271 2.293 1.761 4.196-.676 6.992.347.05.884.26 1.163.436 2.577-3.128 2.074-5.412.562-8.161l-1.05.733Zm3.263-2.398 2.585 3.699c.733 1.049 1.258 1.13 2.465.287l2.033-1.42c1.049-.734 1.017-1.394-.134-3.264-.317.104-.847.24-1.167.23 1.015 1.535 1.088 1.835.59 2.184l-1.85 1.292c-.55.385-.688.383-.963-.01l-2.575-3.685-.984.687Zm-6.234-5.735 1.458 2.085 1.036-.724-1.458-2.085-1.036.724Zm-3.99 4.877 1.944 2.78.996-.696-1.292-1.85 8.997-6.287 1.292 1.85 1.036-.725-1.943-2.78-11.03 7.708Zm16.756-6.498.641.918 3.266-2.282-.642-.918-3.265 2.282Zm2.872-2.007.12.17c1.94 2.86 2.561 3.89 2.53 4.263-.01.183-.132.287-.315.416-.223.155-.8.559-1.427.919.318.148.689.455.917.725.66-.423 1.26-.822 1.574-1.1.34-.295.485-.534.53-.917.019-.56-.673-1.716-2.678-4.726l-.294-.42-.957.67Zm.729-6.834c1.128-.145 2.606-.182 3.425-.07l.149-1.1c-.833-.102-2.309-.008-3.415.141l-.16 1.029Zm4.359 2.243c1.225 3.848 1.053 7.54-.62 10.818.348.05 1 .2 1.281.295 1.58-3.465 1.765-7.362.487-11.564l-1.148.451Zm-9.76 3.854.678.97 10.15-7.093-.678-.97-10.15 7.093Zm-.63.44 2.576 3.686c1.2 1.718 2.659 4.056 2.697 6.43.32-.047.977 0 1.285.078-.007-2.552-1.649-5.348-2.95-7.21l-2.585-3.699-1.023.715Zm3.903-6.007c4.443 6.246 9.582 11.086 12.258 9.217.996-.697.915-1.596-.47-3.967a3.16 3.16 0 0 1-1.227.058c1.14 1.8 1.446 2.6 1.079 2.857-1.705 1.191-6.427-2.828-10.617-8.88l-1.023.715Z"/>
            <path fill="#D9D9D9" d="m36.71 54.907 3.229 2.356 3.237-2.342-1.242 3.798 3.227 2.355-3.996-.008-1.242 3.798-1.227-3.803-3.996-.009 3.238-2.342-1.227-3.803Zm11.572-5.532 3.228 2.356 3.238-2.342-1.243 3.798 3.227 2.355-3.995-.008-1.243 3.797-1.227-3.803-3.996-.008 3.238-2.342-1.227-3.803Zm9.227-8.773 3.227 2.356 3.238-2.342-1.243 3.797 3.228 2.356-3.996-.008-1.243 3.797-1.226-3.803-3.996-.008 3.238-2.342-1.227-3.803Z"/>
           </svg>
          </div>
        <el-divider content-position="left">任务操作</el-divider>
        <!-- 任务处理 -->
        <div class="workPlace" v-if="taskDetail.status === 1">
         <!--显示上传文档-->
          <div class="filesList pb-20" v-if="departmentFileList.length > 0">
          <!-- 已经上传文档 -->
          <div v-for="(item, index) in departmentFileList" :key="index" class="item">
            <div v-if="item.ext === 'docx' ||  item.ext === 'doc'">
              <i class="el-icon-document"></i>
              {{ item.file_name }}
            </div>
            <div v-else-if="item.ext === 'pdf'">
              <i class="el-icon-collection-tag"></i>
              {{ item.file_name }}
            </div>
             <div v-else-if="item.ext === 'jpg' || item.ext === 'gif' || item.ext === 'png'">
              <el-image :src="item.url" style="width: 40px; height: 40px" :preview-src-list="[item.url]" lazy>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div v-else>
              <i class="el-icon-collection"></i>
              {{ item.file_name }}
            </div>
          </div>
          </div>
          <div class="filesList" v-if="departmentImgList.length > 0">
          <!-- 已经上传图片 -->
          <div v-for="(item, index) in departmentImgList" :key="index" class="item">
            <el-image :src="item.url" style="width: 40px; height: 40px" :preview-src-list="[item.url]" lazy>
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
          </div>
          </div>
          <div v-if="taskDetail.current_department_completed">
            <div class="taskOperat taskDo">
              <!-- 已完成处理显示 -->
              <!--仅通知显示操作人-->
                <div class="noticInfo pb-20" v-if="taskDetail.items[0] == 1">
                  {{taskDetail.user.name}} 已提交
                </div>
                <!--显示上传文件-->
                <div class="filesList pb-20" v-if="taskDetail.items[0] == 2">
                  <!-- 已经上传图片 -->
                  <ul>
                    <li v-for="(item,index) in departmentImgList" :key="index">
                    {{item}}
                    </li>
                  </ul>
                </div>
            </div>
      </div>
          <!-- 未完成处理显示 -->
          <div class="taskInComplete" v-show="showScore" v-else>
            <!--上传文件类型的任务操作台-->
            <div class="uploadFiles" v-if="taskDetail.items[0] == 3">
              <!-- 未上传 -->
              <div v-if="resDepartmentTask.attachments">
                  <el-upload
                  :action="uploadURL"
                  :accept="accetpFilesEx"
                  multiple
                  :limit="20"
                  :headers="headerObj"
                  :on-exceed="handleExceed"
                  :on-success="handleUploadSuccess"
                  :on-remove="handleRemoveFile"
                  :before-remove="beforeRemove"
                  :data="uploadFilesData">
                  <el-button size="small" type="primary">点击上传文档</el-button>
                  <div slot="tip" class="el-upload__tip">可直接选择图片，文件等20个以内文件
                    <p>单个文件小到50M</p>
                    <p>已经提交的文件，再次提交会删除之前上传的文件</p>
                    <p>提交后需等待管理员审核，审核通过后=任务完成</p>
                  </div>
                </el-upload>
            </div>
            <div v-else-if="resDepartmentTask.verify_info.status == 1">
              恭喜：任务已完成，感谢您的请及时响应！
            </div>
            <!-- <div v-else>
              <p>恭喜：任务已完成！</p>
            </div> -->
            </div>
            <!--上传图片-->
            <div class="uploadImg" v-if="taskDetail.items[0] == 2">
              <el-upload
                class="img-uploader"
                :action="uploadURL"
                :data="uploadImgData"
                :headers="headerObj"
                list-type="picture-card"
                :on-success="handlePicsSuccess"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemovePic">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
            <!-- 仅通知 -->
            <div class="noticBtn" v-if="taskDetail.items[0] == 1">
              <el-button type="primary">通知已收到</el-button>
            </div>
          </div>
          </div>
          <div v-else-if="taskDetail.status === 4">
            <el-alert
              title="任务尚未开始，无需处理。"
              type="warning"
              :closable="false"
              :description="'开始时间：' + taskDetail.start_date_time"
              show-icon>
            </el-alert>
          </div>
          <div v-else-if="taskDetail.status === 3">
            <el-alert
              title="任务已经完成"
              type="success"
              :closable="false"
              :description="'开始时间：' + taskDetail.start_date_time"
              show-icon>
            </el-alert>
          </div>
          <div v-else>
            <el-alert
              title="任务已经结束。"
              type="info"
              :closable="false"
              :description="'结束时间：' + taskDetail.end_date_time"
              show-icon>
            </el-alert>
          </div>
          <!-- 部门完成情况显示 -->
        <el-divider content-position="left">参与部门</el-divider>
        <div class="taskDepartment">
          <ul>
            <li v-for="(item, index) in departmentList" :key="index">
              <span class="status" v-if="item.status_name == '已完成'">
                <el-tooltip content="已完成" placement="right">
                <i class="el-icon-circle-check success"></i>
                </el-tooltip>
                </span>
              <span class="status" v-else-if="item.status_name == '进行中'">
                <el-tooltip content="进行中" placement="right">
                <i class="el-icon-right default"></i>
                </el-tooltip>
                </span>
              <span class="status" v-else>
                <el-tooltip content="逾期了" placement="right">
                <i class="el-icon-circle-close danger"></i>
                </el-tooltip>
              </span>
              <span class="title">{{item.department_name}}</span>
              <span v-if="item.completed_at != null" class="date">{{item.completed_at}}</span>
            </li>
          </ul>
        </div>
        <!-- 操作按钮 -->
        <div class="ftcontrol" v-if="!taskDetail.current_department_completed">
            <!-- 未提交资料 -->
            <div>
            <el-button type="primary" :disabled="taskDoQuery.attachments.files.length > 0 || taskDoQuery.attachments.images.length > 0 ? false:true" @click="sureHandleTaskDone">提交文件</el-button>
            </div>
            </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Welcome',
  data() {
    return {
      tableDiffFilter: [
        { text: 'month', value: 'month' },
        { text: 'week', value: 'week' },
        { text: 'today', value: 'today' }
      ],
      loading: true,
      // 页面名称
      pagetitle: '我的待办',
      // 可上传的图片类型
      accetpFilesEx: '.xls,.xlsx,.docx,.git,.pdf,.jpg,.png,.jpeg,.rar,.zip,.doc,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      accetpPicEx: '',
      // 可上传的图片类型
      uploadImgData: {
        type: 1
      },
      uploadFilesData: {
        type: 2
      },
      headerObj: {
        Authorization: 'Bearer ' + window.sessionStorage.getItem('token')
      },
      uploadURL: 'https://todo.1tomall.com/api/upload/attachment',
      dialogImageUrl: '',
      dialogVisible: false,
      taskDetailQsObj: {
        id: 0
      },
      taskDetail: {
        current_department_completed: false,
        items: [],
        user: {
          name: ''
        },
        department_task: {
          verify_info: {
            status: 0,
            remark: null
          },
          attachments: null
        }
      },
      resDepartmentTask: {
        verify_info: {
          status: 0,
          remark: null,
          verify_time: null,
          verify_user_id: null
        },
        attachments: {
          files: [],
          images: []
        }
      },
      drawer: false,
      // 得分情况
      statusYear: 2023,
      showScore: false,
      score: 0,
      // 查询参数对象
      queryInfo: {
        page: 1,
        pageSize: 1000,
        status: 1,
        project_id: null
      },
      // 当前部门的资料列表或图片列表
      departmentFileList: [],
      departmentImgList: [],
      // 任务列表
      taskList: [],
      otaskList: [],
      // 总数据条数
      total: 0,
      ctotal: 0,
      // 上传文件需要提交的数据 task/done
      taskDoQuery: {
        task_id: 1,
        attachments: {
          files: [],
          images: []
        }
      },
      // 部门完成情况请求参数
      departmentQs: {
        task_id: 1,
        complete_status: null,
        pageSize: 50
      },
      // 部门完成情况列表
      departmentList: {}
    }
  },
  filters: {
    taskFormat(val) {
      let statusText = ''
      if (val === 1) {
        statusText = '未完成'
      } else if (val === 2) {
        statusText = '已过期'
      } else if (val === 3) {
        statusText = '已完成'
      } else if (val === 4) {
        statusText = '未开始'
      } else {
        statusText = '已取消'
      }
      return statusText
    }
  },
  created() {
    const dateNow = new Date()
    this.statusYear = dateNow.getFullYear()
    this.getTaskList()
    this.getScore()
    if (window.sessionStorage.getItem('userType') === '1') {
      this.pagetitle = '已发布待办'
    } else {
      this.pagetitle = '我的待办'
    }
  },
  computed: {
    filteredItems_complete() {
      return this.otaskList.filter(item => item.status >= 3)
    },
    filteredItems_expired() {
      return this.otaskList.filter(item => item.status === 2)
    },
    filteredItems_progress() {
      return this.otaskList.filter(item => item.status === 1)
    }
  },
  methods: {
    dateMoment(toEndDate) {
      moment.locale('zh-cn')
      const b = moment(toEndDate)
      // if (moment().isAfter(b)) {
      //   return '过期了'
      // }
      return moment().to(b)
    },
    async getfilesSource(url) {
      const { data: res } = await this.$http.get(url)
      if (res.code !== 200) {
        return this.$message.error('详情打开失败!')
      }
      console.log(res)
    },
    async getTaskDetail(val) {
      if (val) {
        this.taskDetailQsObj.id = val * 1
        this.taskDoQuery.task_id = val * 1
        this.departmentQs.task_id = val * 1
      }
      const { data: res } = await this.$http.post('project/task/detail', this.taskDetailQsObj)
      if (res.code !== 200) {
        return this.$message.error('详情打开失败!')
      }
      // console.log(res.data)
      this.departmentFileList = []
      this.departmentImgList = []
      if (res.data.department_task !== undefined) {
        // 给图片和文件列表赋值
        const hasAttachments = JSON.parse(res.data.department_task.attachments)
        if (hasAttachments) {
          this.departmentFileList = hasAttachments.files
          this.departmentImgList = hasAttachments.images
          console.log(this.departmentFileList)
        }
        if (res.data.department_task.verify_info !== null) {
          this.resDepartmentTask = res.data.department_task
        } else {
          this.resDepartmentTask.verify_info = {
            status: 0,
            remark: '审核中',
            verify_time: null,
            verify_user_id: null
          }
        }
      }
      console.log(this.resDepartmentTask)
      this.taskDetail = res.data
      console.log(this.taskDetail)
      this.drawer = true
      this.getDepartmentList()
    },
    async getScore() {
      const { data: res } = await this.$http.post('project/task/statistics')
      if (res.code !== 200) {
        return this.$message.error('得分获取失败!')
      }
      if (res.data.length > 1) {
        this.score = 100
        this.showScore = false
      } else {
        this.showScore = true
        console.log(res.data)
        this.score = Math.floor(res.data[0].result.progress * 100)
      }
    },
    getList(status) {
      this.queryInfo.status = status
      // this.getTaskList()
      if (status === 1) {
        this.taskList = this.filteredItems_progress
      } else if (status === 2) {
        this.taskList = this.filteredItems_expired
      } else if (status === 3) {
        this.taskList = this.filteredItems_complete
      } else {
        this.taskList = this.otaskList
      }
    },
    // 根据分页获取列表数据
    async getTaskList() {
      const { data: res } = await this.$http.post('project/task/list', this.queryInfo)
      if (res.code !== 200) {
        return this.$message.error('获取列表失败!' + res.message)
      }
      // this.$message.success('获取列表成功!')
      // this.taskList = this.sortData([...res.data], 'end_date_time', 'desc')
      this.taskList = this.sortByDate([...res.data], 'end_date_time')
      this.loading = false
      if (this.ctotal === 0) {
        this.total = res.data.length
        this.ctotal = this.total
        this.otaskList = this.sortByDate([...res.data], 'end_date_time')
      }
      console.log(res.data)
    },
    // 显示的页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getTaskList()
    },
    // 页码的改变
    handleCurrentChange(newValue) {
      this.queryInfo.page = newValue
      this.getTaskList()
    },
    // 编辑
    editTaskbyId(id) {
      this.$router.push('taskAdd?id=' + id)
    },
    // 管理员查看详情
    openById(id) {
      this.$router.push('task?id=' + id)
    },
    async removeById(id) {
      const confirmResult = await this.$confirm('此操作将永久删除任务, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info('删除操作已取消!')
      }
      const { data: res } = await this.$http.post('project/task/del', { id: id })
      if (res.meta.status !== 200) {
        return this.$message.error('删除失败!')
      }
      this.getTaskList()
      this.$message.success('删除成功!')
    },
    editById() {},
    goAddpage() {
      // this.$router.push('goods/add')
    },
    // 多文件处理
    handleExceed(files, fileList) {
      response.log(files, fileList)
    },
    // 处理文件上传
    handleUploadSuccess(response) {
      console.log(response)
      if (response.code === 200) {
        const fileInfo = {
          id: response.data.id,
          ext: response.data.ext,
          // 文档名称
          file_name: response.data.file_name,
          mime_type: response.data.mime_type,
          url: response.data.url
        }
        // this.taskDoQuery.attachments.files = []
        this.taskDoQuery.attachments.files.push(fileInfo)
        console.log(this.taskDoQuery)
      }
      // 1. 拼接得到一个图片信息对象
      // const picInfo = { pic: resposne.data.tmp_path }
      // 2. 将图片信息对象,push到pics数组中
      // this.addForm.pics.push(picInfo)
    },
    // 文件移除
    handleRemoveFile(file) {
      const removeFileName = file.response.data.url
      const filesArray = this.taskDoQuery.attachments.files
      this.taskDoQuery.attachments.files.splice(filesArray.findIndex(item => item.url === removeFileName), 1)
      // console.log(this.taskDoQuery.attachments.files)
    },
    // 图片移除
    handleRemovePic(file) {
      const removeFileName = file.response.data.url
      const imagesArray = this.taskDoQuery.attachments.images
      this.taskDoQuery.attachments.images.splice(imagesArray.findIndex(item => item.url === removeFileName), 1)
    },
    // 处理图片上传
    handlePicsSuccess(response) {
      if (response.code === 200) {
        this.taskDoQuery.attachments.images = []
        const picInfo = {
          id: response.data.id,
          ext: response.data.ext,
          // 文档名称
          file_name: response.data.file_name,
          mime_type: response.data.mime_type,
          url: response.data.url
        }
        this.taskDoQuery.attachments.images.push(response.data.url)
        console.log(this.taskDoQuery)
      }
    },
    // 删除已经上传的文件确认
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name} ？`)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 确认提交并完成任务
    async sureHandleTaskDone() {
      console.log(this.taskDoQuery)
      const { data: res } = await this.$http.post('project/task/done', this.taskDoQuery)
      if (res.code !== 200) {
        return this.$message.error('提交失败，请稍后重试!')
      }
      // 改为已经审核中的图标展示
      // this.taskDetail.current_department_completed = true
      this.getTaskList()
      this.getTaskDetail(this.taskDoQuery.task_id)
      return this.$notify({ title: '成功', message: '文件已经提交，等待审核', type: 'success' })
      // return this.$message.success('文件已经提交，等待审核!')
    },
    // 查看已经完成部门
    async getDepartmentList() {
      const { data: res } = await this.$http.post('project/task/departments', this.departmentQs)
      if (res.code !== 200) {
        return this.$message.error('数据查询失败!')
      }
      // console.log(res.data)
      this.departmentList = res.data
    },
    // 自定义列
    renderHeader(h, { column }) {
      return h('div', column.label)
    },
    renderCell(h, { row }) {
      console.log(taskList)
      const rows = this.taskList.filter(item => {
        const date = new Date(item.end_date_time)
        const month = date.getMonth() + 1
        return month === (new Date(row.end_date_time)).getMonth() + 1
      })
      const months = rows.map(item => {
        const date = new Date(item.end_date_time)
        return `${date.getFullYear()}-${date.getMonth() + 1}`
      })
      return h('div', months.join(', '))
    },
    // 相同月份表格合并显示
    spanMonth({ row, column, rowIndex, columnIndex }) {
    // 获取当前单元格的值
      const currentValue = row[column.property]
      // console.log(currentValue)
      // 获取当前单元格所在列的属性名
      const columnName = column.property
      // console.log(columnName)
      // 获取当前单元格所在行的数据
      const rowData = this.taskList[rowIndex]
      // 获取当前单元格所在列的所有单元格数据
      // console.log(rowData)
      const columnData = this.taskList.map(item => item[columnName])
      // console.log(columnData)
      // 判断当前单元格是否需要合并
      // 如果当前单元格的值与它所在列的其他单元格的值相同，并且它们的end_date_time属于同一个月份，则需要合并
      // console.log(rowData)
      if (currentValue === columnData[0] && this.isSameMonth(rowData)) {
        // 返回一个对象，包含当前单元格需要合并的行数和列数
        return {
          rowspan: this.getMonthRowCount(rowData),
          colspan: 1
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    // 判断一个对象数组中的end_date_time是否属于同一个月份
    isSameMonth(data) {
      // console.log(data)
      const month = new Date(data.end_date_time).getMonth()
      // console.log(month)
      return Object.values(data).every(item => new Date(item.end_date_time).getMonth() === month)
    },
    // 获取一个对象数组中属于同一个月份的数据行数
    getMonthRowCount(data) {
      const month = new Date(data.end_date_time).getMonth()
      return this.taskList.filter(item => new Date(item.end_date_time).getMonth() === month).length
    },
    // 离现在越近的排序
    sortByDate(arr, dateKey) {
      const currentDate = new Date()
      arr.sort((a, b) => {
        const dateA = new Date(a[dateKey])
        const dateB = new Date(b[dateKey])
        if (isNaN(dateA) || isNaN(dateB)) {
          return 0
        }
        const diffA = Math.abs(currentDate - dateA)
        const diffB = Math.abs(currentDate - dateB)
        return diffA - diffB
      })
      return arr
    },
    // 通用函数数据排序
    sortData(data, key, order) {
      if (!data || !Array.isArray(data) || !key || !['asc', 'desc'].includes(order)) {
        throw new Error('Invalid parameters')
      }
      return data.sort((a, b) => {
        const valueA = a[key]
        const valueB = b[key]
        if (typeof valueA === 'undefined' || typeof valueB === 'undefined') {
          throw new Error(`Invalid key "${key}"`)
        }
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return order === 'asc' ? valueA - valueB : valueB - valueA
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          const dateA = new Date(valueA)
          const dateB = new Date(valueB)
          if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
            throw new Error(`Invalid date format for key "${key}"`)
          }
          return order === 'asc' ? dateA - dateB : dateB - dateA
        } else {
          throw new Error(`Invalid value type for key "${key}"`)
        }
      })
    },
    resetDateFilter() {
      this.$refs.filterTable.clearFilter('end_date_time')
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter()
    },
    formatter(row, column) {
      return row.address
    },
    filterTag(value, row) {
      return row.tag === value
    },
    // 翻页
    indexMethod(index) {
      return (this.queryInfo.page - 1) * this.queryInfo.pageSize + index + 1
    },
    // 根据日期进行过滤
    filterDate(value, filter) {
      const date = new Date(value)
      const now = new Date()
      const diff = now.getTime() - date.getTime()
      switch (filter) {
        case 'week':
          return diff <= 7 * 24 * 60 * 60 * 1000
        case 'month':
          return diff <= 30 * 24 * 60 * 60 * 1000
        case 'today':
          return diff <= 24 * 60 * 60 * 1000
        default:
          return true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.total{ border-radius:6px; font-size: 11px;  margin-left: 10px; background: #409EFF; padding:5px; color:#fff; }
.welcome {
  position: relative;
  font-size: 20px;
  text-align: left;
  color:#000;
  background: #fff;
  padding:15px 28px;
  min-height: 100%;
  h3 {
    margin: 0 auto 20px;
    font-size: 30px;
  }
}
.score {
    background-color: #5574ff;
    color: #fff;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    border-radius: 50%;
    width: 80px;
    position: absolute;
    right: 60px;
    top: 20px;
    line-height: 60px;
    height: 80px;
    overflow: hidden;
    box-shadow: 1px 1px 10px #5574ff;
    p {
      font-size: 11px;
      position: absolute;
      top: 38px;
      text-align: center;
      width: 80px;
      line-height: 20px;
      display: block;
    }
    span {
      position: absolute;
      top: 52px;
      text-align: center;
      width: 80px;
      display: block;
      font-size: 11px;
      line-height: 20px;
      color: #c5cffc;
    }
  }
  .drawer__body{padding:0 20px; font-size: 14px; max-height: 100%; overflow: auto;}
  .statics_item{
    text-align:center;
    color:#222;
    font-size: 12px;
    margin-bottom:20px;
    div{
      font-size: 14px;
      color: #5574ff;
    }
  }
  .taskOperat{
    padding:0;
  }
  .el-upload--picture-card {
    width:80px;
    height: 80px;
  }
  .ftcontrol{
    border-top: 1px solid #f2f2f2;
    text-align:left;
    padding:10px;
    position:absolute;
    bottom:0;
    width:100%;
    background: #fff;
  }
  .jobDone{
    width:80px;
    height:80px;
    position: absolute;
    right:40px;
    top:10px;
  }
  .taskInComplete{ padding:0 20px 20px 40px;}
  .taskDepartment{ overflow: auto; max-height:100px}
  .taskDepartment ul{ overflow:hidden; padding-bottom: 20px; padding-left:40px;}
  .taskDepartment ul li{
    font-size: 14px;
    display: flex;
    padding:2px 0;
    float: left;
    width:50%;
    align-items: center;
    span{color:#333}
  }
  .taskDepartment ul li .title{ flex:none; width:120px;}
  .taskDepartment ul li .success{ color: #67C23A;}
  .taskDepartment ul li .default{ color:#5574ff}
  .taskDepartment ul li .danger{ color:peru}
  .taskDepartment ul li .number{width:24px; flex:none; color:#888;}
  .taskDepartment ul li .date{color:#888; font-size: 12px; line-height: 14px;}
  .title{ color: #000;padding:0 10px;}
  .status{ color:#5574ff}
  .content{ padding:20px 45px; max-height: 150px; overflow:auto;}
  .filesList{ text-align: left; padding:0 40px;}
  .filesList .item{ font-size:12px;}
  .filesList .item div i{ font-size: 14px; color:#409EFF}
  .workPlace{background:#f2f2f2; border-radius: 5px; padding-top:20px;}
  .pb-20{padding-bottom:20px}
</style>
<style>
.el-progress__text{ font-size: 12px !important; color:#666;display: block; text-align: left; padding-left:0; margin:0;}
</style>
