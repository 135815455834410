<template>
  <div class="login_container">
    <div class="login_box">
      <!--      头像区域-->
      <div class="avatar_box">
        <img src="~assets/logo.png" alt="" />
      </div>
      <!--      表单提交区域-->
      <el-form :rules="loginFormRules" ref="loginFormRef" label-width="0px" class="login_form" :model="loginForm">
        <!--        用户名-->
        <el-form-item prop="username">
          <el-input v-model="loginForm.username" prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <!--        验证码-->
        <el-form-item prop="password">
          <el-row>
            <el-col :span="16">
              <el-input type="text" v-model="loginForm.password" prefix-icon="el-icon-message" placeholder="验证码"></el-input>
            </el-col>
            <el-col :span="8">
              <el-button @click="getSmsCode" :disabled="isFetchCode">{{txtBtn}}</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <!--        按钮区-->
        <el-form-item class="btns">
          <el-button type="primary" @click="login" :loading="loginLoading">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { isPhone } from '../../common/utils.js'
import md5 from 'js-md5'
export default {
  name: 'Login',
  data() {
    const validatePhone = (rule, value, callback) => {
      if (!isPhone(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      appid: 'wxe4fb6e1a355e48ea',
      isFetchCode: false,
      txtBtn: '发送验证码',
      timer: null,
      smsQuery: {
        phone: 13306003302,
        type: 1,
        sign: '',
        time: 1666305037
      },
      smsCode: '',
      loginLoading: false, // 登录限制
      loginForm: {
        // 登录的表单数据的绑定对象
        username: '',
        password: ''
      },
      queryLogin: {
        code: '081Fyc1w3S6qjZ2NdX0w3Qnshw1Fyc1A',
        phone: 13306003302,
        captcha: '' // 验证码 md5(手机收到的验证码 + md5(appId))
      },
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '长度在 11 个字符', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度在 4 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    if (window.sessionStorage.getItem('token')) {
      this.$router.push('/home')
    }
  },
  beforeDestroy() {
    this.timer = null
    clearInterval(this.timer)
  },
  methods: {
    resetLoginForm() {
      // 点击重置按钮,重置登录表单
      // this.$refs[loginFormRef].resetFields()
      this.$refs.loginFormRef.resetFields()
    },
    // 获取短信
    async getSmsCode() {
      if (isPhone(this.loginForm.username)) {
        this.smsQuery.phone = this.loginForm.username * 1
        const time = Math.floor(new Date().getTime() / 1000)
        this.smsQuery.time = time
        console.log(this.loginForm.username * 1)
        this.smsQuery.sign = md5(this.loginForm.username + md5(this.appid) + time)
        console.log(this.smsQuery)
        const { data: res } = await this.$http.get('sms/code', { params: this.smsQuery })
        console.log(res)
        if (res.code === 200) {
          this.smsCode = res.data.code
          this.isFetchCode = true
          let sec = 60
          this.timer = setInterval(() => {
            if (sec > 0) {
              sec--
              this.txtBtn = '重新获取(' + sec + 's)'
            } else {
              this.timer = null
              clearInterval(this.timer)
              this.txtBtn = '获取验证码'
              this.isFetchCode = false
            }
          }, 1000)
        } else {
          return this.$message.error(res.message)
        }
      } else {
        return this.$message.error('请填写正确手机号')
      }
    },
    login() {
      this.loginLoading = true
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) {
          return (this.loginLoading = false)
        }
        if (this.smsCode !== md5(this.loginForm.password + md5(this.appid))) {
          this.loginLoading = false
          this.$message.error('验证码错误')
          return
        }
        this.queryLogin.phone = this.loginForm.username * 1
        this.queryLogin.captcha = md5(this.loginForm.password + md5(this.appid))
        const { data: res } = await this.$http.post('login', this.queryLogin)
        if (res.code !== 200) {
          this.loginLoading = false
          return this.$message.error('登录失败 帐号或密码错误!')
        }
        this.$message.success('登录成功!')
        // 1. 将登录成功之后的 token,保存到客户端的 sessionStorage(会话机制/只在当前页面生效)中 localStorage(持久话机制/关闭页面也不会忘记数据)
        //   1.1 项目中除了登录之外的API接口,必须在登录之后才能访问
        //   1.2 token 只应在当前网站打开期间生效, 所以将 token 保存在 sessionStorage中
        window.sessionStorage.setItem('token', res.data.token)
        window.sessionStorage.setItem('userType', res.data.type)
        // 2. 通过编程式路由导航跳转到后台主页,路由地址是 /home
        this.$router.push('/home')
        this.loginLoading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  height: 100%;
  background-color: #F4F9FF;
}
.login_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 380px;
  height: 300px;
  border:1px solid rgb(242, 244, 249);
  box-shadow: 0 0 10px 0 rgb(183 192 206 / 20%);
  background-color: #fff;
  border-radius: 3px;
  .avatar_box {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    width: 130px;
    height: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    box-shadow: 0 0 10px #ddd;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eeeeee;
    }
  }
  .login_form {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
